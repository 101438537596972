import { Box, Icon, Text, Center } from '@chakra-ui/react';

import Page from '@inspire/ui/chakra/layout/Page';
import PageHeading from '@inspire/ui/chakra/layout/PageHeading';
import { inspireYellow } from '@inspire/ui/static';
import MediaBlock from '@inspire/ui/general/MediaBlock';
import { NotFoundIcon } from '@inspire/ui/icons';

import LinkButton from 'components/general/LinkButton';
import Layout from 'components/layout/Layout';
import FakeSearchInput from 'components/search/FakeSearchInput';
import { LOGGED_IN_HOME_PATH, LOGGED_OUT_HOME_PATH } from 'lib/paths';

const Erro404Page = ({ user }) => (
  <Page title="Page introuvable">
    <MediaBlock
      rowMargin={50}
      rowMediaCentered
      media={
        <Box
          as="span"
          display="inline-flex"
          borderRadius={999}
          background="black"
        >
          <Icon
            as={NotFoundIcon}
            boxSize={[16, null, 24]}
            color={inspireYellow}
          />
        </Box>
      }
      content={
        <Box textAlign={['center', null, 'left']}>
          <PageHeading>Il n'y a rien ici...</PageHeading>
          <Text textAlign="center">
            ... mais on peut sûrement t’aider à trouver ton orientation !
          </Text>
          <FakeSearchInput mb={10} w="100%" />
          <Center>
            <LinkButton
              href={user ? LOGGED_IN_HOME_PATH : LOGGED_OUT_HOME_PATH}
            >
              Retour à l'accueil
            </LinkButton>
          </Center>
        </Box>
      }
    />
  </Page>
);

Erro404Page.getWrappers = (page) => (
  <Layout rowVariant="gray" containerWidth="sm" centerVertically>
    {page}
  </Layout>
);

export default Erro404Page;
